import React from 'react';
import { useTranslation } from 'next-i18next';

const useTranslationLgs = () => {
  const { t, i18n } = useTranslation('common');

  const interpolate = (key: string, lng: string, i18nData): string => {
    const k = `${key}/${lng}`;
    const v = i18nData[k];
    return v ? v : k;
  };

  const translateModel = (modelPrefix: string, key: string) => {
    return t(key, key);
  };

  const tr = (
    translationKey: string,
    defaultValue: string,
    data: { [key: string]: any } = {},
    count: number = undefined,
  ) => {
    // company specific data
    return t(translationKey, defaultValue, { count, replace: data });
  };

  return { tr };
};

export default useTranslationLgs;
